<template>
    <div>
        <h2>Executive Report</h2>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>